// @font-face {
//   font-family: "CamptonMedium";
//   src: local("CamptonMedium"), url("fonts/CamptonMedium.otf") format("opentype");
// }

@font-face {
  font-family: "CamptonMedium";
  src: local("CamptonMedium"), url("fonts/Campton-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "CamptonBook";
  src: local("CamptonBook"), url("fonts/CamptonBook.otf") format("opentype");
}

@font-face {
  font-family: "Campton";
  src: local("Campton"), url("fonts/Campton.woff") format("woff");
}

@font-face {
  font-family: "CamptonBold";
  src: local("CamptonBold"), url("fonts/CamptonBold.otf") format("opentype");
}

// @font-face {
//   font-family: "CamptonBold";
//   src: local("CamptonBold"), url("fonts/Campton-Bold.ttf") format("truetype");
// }

// @font-face {
//   font-family: "CamptonBold";
//   src: local("CamptonBold"), url("fonts/Campton-Bold.woff") format("woff");
// }

// @font-face {
//   font-family: "CamptonLight";
//   src: local("CamptonLight"), url("fonts/CamptonLight.otf") format("opentype");
// }

@font-face {
    font-family: "CamptonLight";
    src: local("CamptonLight"), url("fonts/Campton-Light.woff") format("woff");
  }

  @font-face {
    font-family: "CamptonMediumItalic";
    src: local("CamptonLight"), url("fonts/CamptonMediumItalic.otf") format("opentype");
  }

@font-face {
  font-family: "CamptonSemiBold";
  src: local("CamptonSemiBold"),
    url("fonts/CamptonSemiBold.otf") format("opentype");
}


$tmavo-modra: #005e9d;
$stisknuta: #73ADD3;
$hover: #BAD6E8;
$siva:#B0B0B0;
$zakaz: #6E0D25;

html {
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  text-rendering: optimizeLegibility;
}
a {
  text-decoration: none;
}

body {
  font-family: "CamptonBook";
  font-size: 13px;
  margin: 0;
  background-color: white;
}

table {
  border-collapse: collapse;
}

table {
  border: 2px solid;
}

td,
th {

  border: 1px solid;
  text-align: center;
  height: 30px;
}

thead {
  /* background-color: #c4e6fa; */
  color: #000;
}


#order_code_label {
  margin-left: 10px;
  padding: 5px 0;
}

#kodmaly {
  border: 0;
  margin-right: 10px;
}

.prava_strana {
  display: flex;
  align-items: center;

}

.obalena_tab1 {
  display: flex;
  align-items: start;
  flex-direction: column;
}



.kod_cisla {
  display: flex;
  align-items: center;

}



#objednavaci_kod {
  margin-top: 20px;
  position: sticky;
  top: 10px;
  margin-bottom: 20px;
  font-family: "CamptonBold";
  background-color: $tmavo-modra;
  color: $tmavo-modra;
  font-size: 16px;
  table-layout: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 3px solid #003860;

}

.detail {
  margin-left: 60px;
}

.cislo {
  border-left: 1px solid white;
  width: 32px;
  text-align: center;
}

.objednavaci_riadok {
  color: white;
}


.hlavny_obrazok {
  height: 160px;
}

.dole_desifrovac {
  margin: auto;
}

.vynimky-margin {
  margin-left: 60px;
  width: 500px;
}

.zakaznicke-margin {
  margin-left: 60px;
  width: 97%;
  // width:700px;
}


.siedma_tabulka {
  width: 100%;
  margin-top: 25px
}

#prva0, #druha0, #tretia0, #stvrta0, #piata0, #siesta0 {
  margin-top: 40px;
}

.objednavaci_a_board {
width: 100%;
}




/* CSS */
.button-51 {
  background-color: transparent;
  border: 1px solid #266db6;
  box-sizing: border-box;
  color: #00132c;
  font-family: "Avenir Next LT W01 Bold", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 16px 23px;
  position: relative;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-51:hover,
.button-51:active {
  outline: 0;
}

.button-51:hover {
  background-color: transparent;
  cursor: pointer;
}

.button-51:before {
  background-color: #d5edf6;
  content: "";
  height: calc(100% + 3px);
  position: absolute;
  right: -7px;
  top: -9px;
  transition: background-color 300ms ease-in;
  width: 100%;
  z-index: -1;
}

.button-51:hover:before {
  background-color: #6dcff6;
}

@media (min-width: 768px) {
  .button-51 {
    padding: 16px 32px;
    margin-right: 18px;
    margin-bottom: 16px;
  }
}



.input-wrapper {
  height: 2.5rem;
  border: none;
  border-radius: 10px;
  padding: 0 15px;
  box-shadow: 0px 0px 8px #ddd;
  background-color: white;
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 40px;
  width: 400px;
}

.cislo_objednavacie {
  background-color: transparent;
  border: none;
  height: 100%;
  font-size: 1.25rem;
  width: 100%;
  margin-left: 5px;
  font-family: "CamptonBook";
}

.cislo_objednavacie:focus {
  outline: none;
}

#search-icon {
  color: royalblue;
}

.desifrovac {
  font-size: 1.25rem;
  margin: auto;
  font-family: "CamptonBook";
  margin-bottom: 40px;
}

#headerup {
  display: flex;
  width: 100%;
  // overflow: auto;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  background-color: $tmavo-modra;
}

.headerup-menu {
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: center;
  align-items: center;
  background-color: #141414;
  color: white;
  font-size: 16px;
}


.menu_icons {
  width: 24px;
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(317deg) brightness(105%) contrast(104%);
  padding: 5px;
}

.cele_nazvy {
  display: flex;
  align-items: stretch;
  margin-bottom: 10px;
  gap: 4px;
}

.mobile_nazvy {
  display: none;
}

.mobile_objednavaci {
  display: none;
}



#headerup-final {
  display: flex;
  background-color: $tmavo-modra;
  width: 100%;
  height: 60px;
  justify-content: center;
  align-items: center;
}



#typografia_header {
  height: 35px;
  color: white;
}

#language_header {
  // all:unset;
  margin-right: 20px;
  color: white;
  background-color:$tmavo-modra;
  border: 0;
  padding: 0 2px 0 10px;
  border-radius: 25px;
  font-size: 19px;
  cursor: pointer;
}

#back_header {
  margin-left: 20px;
  color: white;
  font-family: "CamptonMedium";
  font-size: 19px;
  display:flex;
  transform: rotate(180deg);
  width: 30px;
  margin-right: 10px;
  cursor: pointer;
  color: white;
}


#nazov {
  font-family: "CamptonBold";
  font-size: 40px;
}


.nazov_final {
  font-family: "CamptonBold";
  font-size: 30px;
  margin-bottom: 4px;
}

.nestisknute {
  cursor: pointer;
}

.hodnoty_v_tabulke:last-child {
  width: 22px;
}

.hodnoty_v_rozsirenej:nth-last-child(2) {
  width: 22px;
}

.hodnoty_v_rozsirenej:last-child {
  width: 22px;
}

.hodnoty_v_rozsirenej:first-child {
  width: 22px;
}

#vybavenia_obvidva {
  display: flex;
  flex-grow: 0;
  gap: 60px;
}

#typo_obal {
  height: 35px;
  cursor: pointer;
}

.vybavenia_jedno {
  max-width: 400px;
}

.vybavenia_nazvy {
  margin-top: 0;
  margin-bottom: 16px;
  font-family: "CamptonMedium";
}

.nahlad {margin: auto;}

.nazov_obrazok {
  display: flex;
  align-items: center;
  margin-top: 22px;
  justify-content: space-between;
  width: 100%;
}

.nazov_obrazok_final {
  display: flex;
  align-items: start;
  margin-top: 26px;
  justify-content: space-between;
  width: 100%;
}

.lavy_margin-konfi {
  margin-left: 60px;
}

.pravy_margin-konfi {
  margin-right: 60px;
}

$base-spacing-unit: 24px;
$half-spacing-unit: $base-spacing-unit / 2;

$color-alpha: #1772FF;
$color-form-highlight: #EEEEEE;



h1,h2,h3,h4,h5,h6 {
	margin:0;
}

.container {
  margin-top: 40px;

	justify-content:start;
	align-items:start;
  display: flex;
  flex-direction: column;
  font-size: 13px;

}


.table-header {
	display:flex;
  align-items: center;
	width:100%;
	background: $tmavo-modra;
  font-family: "CamptonBold";
	padding: 8px 0;
  margin-bottom: 10px;
}

.table-row {
	display:flex;
  align-items: center;
  border: 2px solid $tmavo-modra;
  width: 99.7%;
  padding: 7px 0;
  font-family: "CamptonMedium";
  justify-content: flex-start;
	margin-bottom: 10px;
  background-color: white;
  cursor: pointer;
}


.table-row-historia {
  display:flex;
  align-items: center;
  padding: 7px 0;
  font-family: "CamptonMedium";
  justify-content: flex-start;
  
}
.collapsable_row {
  width: 99.6%;
  border: 2px solid $tmavo-modra;
  margin-bottom: 12px;
  cursor: pointer;
}


.additional-content {
  width: 100%;
}


.table-row-x {
	display:flex;
  align-items: center;
  border: 2px solid $tmavo-modra;
  width: 99.6%;

  font-family: "CamptonMedium";
  justify-content: flex-start;
	margin-bottom: 10px;
  cursor: pointer;
}

.table-row-x:hover {
  background-color: $hover;
  border: 2px solid $hover;
  color: white;
}

.poznamka-x {
  // padding: 6px 0;
  margin-bottom: 10px;
  font-family: "CamptonMediumItalic";
}



.table-row-siedma {
  display:flex;
  align-items: center;
  border: 2px solid $tmavo-modra;
  width: 99.6%;
  padding: 7px 0;
  font-family: "CamptonMedium";
	margin-bottom: 10px;
  cursor: pointer;
}

.table-row-siedma-edit {
  display:flex;
  align-items: center;
  border: 2px solid $tmavo-modra;
  width: 99.6%;
  padding: 7px 0;
  font-family: "CamptonMedium";
	margin-bottom: 10px;
  cursor: pointer;
}

.table-row-siedma:hover {
  background-color: $hover;
  border: 2px solid $hover;
  width: 99.6%;
  color: white;
}


.table-row-zakaznicke {
  border: 2px solid $tmavo-modra;
  width: 99.6%;
  padding: 6px 0;
  font-family: "CamptonMedium";
	margin-bottom: 10px;
  cursor: pointer;
}


.table-row:hover {
  background-color: $hover;
  border: 2px solid $hover;
  color: white;
}

.table-row-zakaznicke:hover {
  background-color: $hover;
  border: 2px solid $hover;
  padding-right: 6px;
  color: white;
}


.stisknute {

  background-color: $stisknuta;
  border: 2px solid $stisknuta;

  
  color: white;
  cursor: pointer;
}



.nemozes {
  background-color: rgba(190,187,187, 0.1);
  border: 2px solid $siva;
  width: 99.7%;
  color: $siva;
  cursor: not-allowed;
}


.table-data, .header__item {
  all: unset;
  flex: 1;
	text-align:center;
  padding: 0 3px;
  overflow-wrap: break-word; 
  // min-width: 0
}

.table-data:last-child, .header__item:last-child {
	flex: 0 0 27px;
  padding-left: 1px
}



.table-data-zakaznicke {
  all: unset;
  padding: 7px 0;
  flex: 1;
  padding-left: 10px;
  // color: #005E9D;

}


.table-data-historia {
  all: unset;
  flex: 1;
	text-align:center;
  padding: 0 3px;
  overflow-wrap: break-word; 
}


.table-data-zakaznicke:hover::placeholder{
  color: white;
}

.table-data-zakaznicke:focus::placeholder{
  color: white;
}

.table-data-zakaznicke:last-child {
  flex: 0 0 33px;
  text-align: center;
  padding: 6px 0;
  // padding: 0;
  // color: #000;
  font-family: "CamptonMedium";
}

.siedma_item {
  flex: 1 1 20%;
	text-align:center;
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  position: relative;
  display: inline-block;
}

.siedma_item:nth-last-child(2) {
  flex: 0 0 33px,
}
.siedma_item:last-child {
  flex: 0 0 33px,
}

.siedma_item:first-child {
  flex: 0 0 80px,
}

.siedma_item-edit:first-child {
  flex: 0 0 80px,
}

.siedma_data {
  flex: 1 1 20%;
	text-align:center;
}

.siedma_data-edit:nth-last-child(2), .siedma_data-edit:last-child {
  flex: 0 0 33px,
}

.siedma_data-edit:first-child {
  flex: 0 0 80px,
}


.siedma_data:last-child,.siedma_data:nth-last-child(2) {
  flex: 0 0 33px,
}

.siedma_data:first-child {
  flex: 0 0 80px,
}

.header__item {
	text-transform:uppercase;
  color:white;
	text-decoration: none;
	position:relative;
	display:inline-block;

}





.dov_kombinacie {
	display:flex;

  border: 2px solid $tmavo-modra;
  width: 99.7%;
  padding: 7px 0;
  
  font-family: "CamptonMedium";
	margin-bottom: 10px;
}

#kombinacie {
  margin-left: 20px;
}

#druha_strana {
  padding-top: 10px;
}


// pouziva sa na malych screen a landscape pre telefon
// @media screen and (orientation:landscape) {
  #headerup {
    width: 100%;
  }

  .board {
    display: flex;
    margin-bottom: 50px;
    width: 714px;
    flex-direction: column;
    
  }

  .prva_tabulka {
    width:420px;
  }
  .druha_tabulka {
    width: 484px; 
  }
  .tretia_tabulka {
    width: 517px;
  }
  .stvrta_tabulka {
    width: 550px;
  }
  .piata_tabulka {
    width: 583px;
  }
  .siesta_tabulka {
    width: 616px;
  }


  .prva_tabulka-3 {
    width: 386px;
  }
  .druha_tabulka-3 {
    width: 449px; 
  }
  .tretia_tabulka-3 {
    width: 488px;
  }
  .stvrta_tabulka-3 {
    width: 528px;
  }
  .piata_tabulka-3 {
    width: 562px;
  }
  .siesta_tabulka-3 {
    width: 598px;
  }

  .prva_tabulka-4 {
    width: 352px;
  }
  .druha_tabulka-4 {
    width: 415px; 
  }
  .tretia_tabulka-4 {
    width: 451px;
  }
  .stvrta_tabulka-4 {
    width: 485px;
  }
  .piata_tabulka-4 {
    width: 524px;
  }
  .siesta_tabulka-4 {
    width: 564px;
  }

  .edit-row-cely {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 108%;
  }


  
  


// VACSIE AKO TELEFON
@media screen and (min-width: 840px) {

  .board {
    display: flex;
    margin-bottom: 50px;
    width: 1000px;
    flex-direction: column;
    
  }

  .prva_tabulka {
    width:705px;
  }
  .druha_tabulka {
    width: 770px; 
  }
  .tretia_tabulka {
    width: 803px;
  }
  .stvrta_tabulka {
    width: 836px;
  }
  .piata_tabulka {
    width: 870px;
  }
  .siesta_tabulka {
    width: 903px;
  }

  


  .prva_tabulka-3 {
    width: 671px;
  }
  .druha_tabulka-3 {
    width: 736px; 
  }
  .tretia_tabulka-3 {
    width: 766px;
  }
  .stvrta_tabulka-3 {
    width: 802px;
  }
  .piata_tabulka-3 {
    width: 837px;
  }
  .siesta_tabulka-3 {
    width: 872px;
  }

  .prva_tabulka-4 {
    width: 641px;
  }
  .druha_tabulka-4 {
    width: 705px; 
  }
  .tretia_tabulka-4 {
    width: 743px;
  }
  .stvrta_tabulka-4 {
    width: 777px;
  }
  .piata_tabulka-4 {
    width: 813px;
  }
  .siesta_tabulka-4 {
    width: 849px;
  }







  .edit-row-cely {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 104.9%;
  }

  .filterMenuClass {
    display: flex;
    align-items: start;
    margin-right: 30px;
  }

  .vyhladavanieClass{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 14px;
    margin-top: 6px;
  }

}

// PRE TELEFON
@media screen and (max-width: 840px) {

// .hidePhones {
//   visibility: hidden;
// }
#objednavaci_kod {
  display: none;
}
#vybavenia_obvidva {
  display: none;
}


.hiddenOnPhone1 {
  display: none;
   }
 
#headerUpIcons {
  display: none;
}

#filterMenu {
  display: none;
}

.cele_nazvy {
    display: none;
}

.vyhladavanieClass{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 14px;
  gap: 20px;
}

.input-wrapper {
  height: 2.5rem;
  border: none;
  border-radius: 10px;
  padding: 0 15px;
  box-shadow: 0px 0px 8px #ddd;
  background-color: white;
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 40px;
  width: 300px;
}


.obalena_tab1 {
  margin-left: 5px;
}

.nazov_obrazok {
  justify-content: space-between;
  width: 100vw;

}

#nazov {
  font-size: 22px;
};

.hlavny_obrazok{
  height: 80px;
  margin-right: 10px;
}
.pravy_margin-konfi {
  margin: 10px;
}

.detail {
  margin: 5px;;
}

.lavy_margin-konfi
{
  margin: 3px;
}

.board {
  width: 96vw;
}


.prva_tabulka, .druha_tabulka, .tretia_tabulka, .stvrta_tabulka,  .piata_tabulka,  .siesta_tabulka, .siedma_tabulka {
  width: 95vw;
}

.prva_tabulka-3, .druha_tabulka-3, .tretia_tabulka-3, .stvrta_tabulka-3,  .piata_tabulka-3,  .siesta_tabulka-3, .siedma_tabulka-3 {
  width: 95vw;
}

.prva_tabulka-4, .druha_tabulka-4, .tretia_tabulka-4, .stvrta_tabulka-4,  .piata_tabulka-4,  .siesta_tabulka-4, .siedma_tabulka-4 {
  width: 95vw;
}

.mobile_nazvy {
  display:block ;
  font-size: 22px;
  margin-bottom: 8px;

}
.container {
  margin-top: 0;
}

.auth-modal {
  width: 760px;
}


.mobile_objednavaci {
  display: block;
  color: $tmavo-modra;
  font-size: 26px;

}

.tlacidla_riadok {
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.cislo {
  all: unset
}

.zakaznicke_nazov_mobile{
  font-size: 20px;
}

}


@media screen and (min-width: 1600px) {

  .board {
    display: flex;
    margin-bottom: 50px;
    width: 1250px;
    flex-direction: column;
    
  }

  .prva_tabulka {
    width: 954px;
  }
  .druha_tabulka {
    width: 1020px; 
  }
  .tretia_tabulka {
    width: 1052px;
  }
  .stvrta_tabulka {
    width: 1090px;
  }
  .piata_tabulka {
    width: 1119px;
  }
  .siesta_tabulka {
    width: 1152px;
  }


  .prva_tabulka-3 {
    width: 921px;
  }
  .druha_tabulka-3 {
    width: 977px; 
  }
  .tretia_tabulka-3 {
    width: 1011px;
  }
  .stvrta_tabulka-3 {
    width: 1049px;
  }
  .piata_tabulka-3 {
    width: 1085px;
  }
  .siesta_tabulka-3 {
    width: 1119px;
  }

  .prva_tabulka-4 {
    width: 891px;
  }
  .druha_tabulka-4 {
    width: 952px; 
  }
  .tretia_tabulka-4 {
    width: 990px;
  }
  .stvrta_tabulka-4 {
    width: 1027px;
  }
  .piata_tabulka-4 {
    width: 1062px;
  }
  .siesta_tabulka-4 {
    width: 1097px;
  }

  .edit-row-cely {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 103.7%;
  }

}

@media screen and (min-width: 1920px) {

  .board {
    display: flex;
    margin-bottom: 50px;
    width: 1550px;
    flex-direction: column;
    
  }

  .prva_tabulka {
    width: 1254px;
  }
  .druha_tabulka {
    width: 1320px; 
  }
  .tretia_tabulka {
    width: 1353px;
  }
  .stvrta_tabulka {
    width: 1386px;
  }
  .piata_tabulka {
    width: 1419px;
  }
  .siesta_tabulka {
    width: 1452px;
  }


  .prva_tabulka-3 {
    width: 1220px;
  }
  .druha_tabulka-3 {
    width: 1275px; 
  }
  .tretia_tabulka-3 {
    width: 1307px;
  }
  .stvrta_tabulka-3 {
    width: 1345px;
  }
  .piata_tabulka-3 {
    width: 1379px;
  }
  .siesta_tabulka-3 {
    width: 1413px;
  }

  .prva_tabulka-4 {
    width: 1190px;
  }
  .druha_tabulka-4 {
    width: 1254px; 
  }
  .tretia_tabulka-4 {
    width: 1291px;
  }
  .stvrta_tabulka-4 {
    width: 1321px;
  }
  .piata_tabulka-4 {
    width: 1351px;
  }
  .siesta_tabulka-4 {
    width: 1387px;
  }


  .edit-row-cely {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 103%;
  }


}

.fixed-bottom-right {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 30px;
  margin-bottom: 20px;
  display: flex;
  font-family: "CamptonMedium";
  flex-direction: column;
  align-items: end;
}

.oknodole{ 
  background-color: white;
  width: 220px;
  height: 60px;
  border: 2px solid $tmavo-modra;
  color: $tmavo-modra;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  justify-content: center;
  align-items: start;
  padding: 2px 10px;
}



.kod_dole {
  font-family: "CamptonMedium";
  margin-bottom: 2px;
  font-size: 15px;
}

.cisla_dole {
  display: flex;
}

.dole_objednavaci {
  font-family: "CamptonBold";
  margin: 0 2px;
}

.oknodole_vynimky{ 
  background-color: white;
  width: 364px;
  height: 400px;
  border: 2px solid $tmavo-modra;
  border-radius: 1px;
  color: $tmavo-modra;
  overflow: scroll;
  padding: 0px 10px 6px 10px;
  
}




.tlacitko-konfi {
  border: 1px solid $tmavo-modra;
  // padding: 10px 16px;
  width: 200px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  background-color: white;
  color: $tmavo-modra;
  font-family: "CamptonMedium";
  font-size: 16px;
}

.tlacitko-konfi:hover {
  background-color: $tmavo-modra;
  border: 1px solid $tmavo-modra;
  color: white;
  cursor: pointer;
}


.tlacitko{
  border: 1px solid $tmavo-modra;
  padding: 10px 16px;
  background-color: white;
  color: $tmavo-modra;
  font-family: "CamptonMedium";
  font-size: 16px;
}

.tlacitko:hover {
  background-color: $tmavo-modra;
  border: 1px solid $tmavo-modra;
  color: white;
  cursor: pointer;
}

.tlacitko-chyba {
  border: 1px solid $zakaz;
  padding: 10px 16px;
  background-color: white;
  color: $zakaz;
  font-family: "CamptonMedium";
  font-size: 16px;
}

.tlacitko-chyba-konfi {
  border: 1px solid $zakaz;
  // padding: 10px 16px;
  width: 200px;
  height: 40px;
  text-align: center;
  line-height: 38px;
  background-color: white;
  color: $zakaz;
  font-family: "CamptonMedium";
  font-size: 16px;
}

.tlacitko-chyba:hover {
  background-color: $zakaz;
  border: 1px solid $zakaz;
  color: white;
  cursor: pointer;
}

.tlacitko-chyba-konfi:hover {
  background-color: $zakaz;
  border: 1px solid $zakaz;
  color: white;
  cursor: pointer;
}

.tlacitko_stlacene {
  border: 1px solid $tmavo-modra;
  padding: 10px 16px;
  background-color: $tmavo-modra;
  color: white;
  font-family: "CamptonMedium";
  font-size: 16px;
}


.tlacitko-male {
  border: 1px solid $tmavo-modra;
  padding: 6px 12px;
  background-color: white;
  color: $tmavo-modra;
  font-family: "CamptonMedium";
}


.tlacitko-male:hover {
  background-color: $tmavo-modra;
  border: 1px solid $tmavo-modra;
  color: white;
  cursor: pointer;
}

.sedive-tlacitko-male {
  border: gray 1px solid;
  padding: 6px 12px;
  background-color: white;
  color: gray;
  font-family: "CamptonMedium";
}





// .tlacitko-male_zakaz{
//   margin-top: 10px;
//   border: 1px solid $siva;
//   padding: 8px 14px;
//   background-color: white;
//   color: $siva;
// }

.typove_cislo {display: flex;}




.row {
  display: flex;
  width: 100%;
  background: $tmavo-modra;
  font-family: "CamptonBold";
  align-items: center;
  padding: 8px 0;
  flex: 1;
  
}

.row-dopyt {
  display: flex;

  width: 100%;
  background: $tmavo-modra;
  font-family: "CamptonBold";
  margin-bottom: 5px;
  padding: 8px 0;
}


.row:last-child {
  flex: 0 0 33px;
  margin-right: 0;
}

.element_nazov {
  flex: 1;
  color:white;
  text-transform:uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  text-align: center;
  padding: 0 4px;
  
}



.element_nazov_historia {
  all: unset;
  flex: 1;
  text-align: center;
  color:white;
  text-transform:uppercase;
  padding: 0 1px;
  cursor: pointer;
}


.group {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  gap: 4px;
}


.table-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.redd {
  background:$zakaz;
}

.flex1 {
  flex: 1;
}
.flex2 {
  flex: 2;
}
.flex3 {
  flex: 3;
}
.flex4 {
  flex: 4;
}
.flex5 {
  flex: 5;
}
.flex6 {
  flex: 6;
}
.flex7 {
  flex: 7;
}
.flex8 {
  flex: 8;
}

.pozeras_tabulku {
  color: #000;
  font-family: "CamptonSemiBold";
}

.checkmark {
  width: 30px;
}

.checkmark:hover {
  cursor: pointer;
}





.loading_screen {
  --hue: 223;
  --fg: white;
	--trans-dur: 0.3s;
  // overflow-x: hidden;
	font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1280 - 320));
	background-color: var(--bg);
	color: var(--fg);
	font: 1em/1.5 sans-serif;
	height: 100vh;
	display: grid;
	place-items: center;
	transition: background-color var(--trans-dur);
}
main {
	padding: 1.5em 0;
}
.ip {
	width: 16em;
	height: 8em;
}
.ip__track {
  stroke: $siva;
	transition: stroke var(--trans-dur);
}
.ip__worm1,
.ip__worm2 {
	animation: worm1 2s linear infinite;
}
.ip__worm2 {
	animation-name: worm2;
}


/* Animation */
@keyframes worm1 {
	from {
		stroke-dashoffset: 0;
	}
	50% {
		animation-timing-function: steps(1);
		stroke-dashoffset: -358;
	}
	50.01% {
		animation-timing-function: linear;
		stroke-dashoffset: 358;
	}
	to {
		stroke-dashoffset: 0;
	}
}
@keyframes worm2 {
	from {
		stroke-dashoffset: 358;
	}
	50% {
		stroke-dashoffset: 0;
	}
	to {
		stroke-dashoffset: -358;
	}
}

.tlacidla_riadok{
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}

.nezvolene_tabulky {
  color: #6E0D25;
  font-family: "CamptonSemiBold";
  font-size: 15px;
}

.cervene_x {
  color: #6E0D25;
}

.desifrovac_tlacidla {
  // width: %.;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}





.obsah_okno_dole {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

#spajanieSchemContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}




.nazov_typove {
  display: flex;
  flex-direction: column;
}

// FORMULAR

.auth-modal {
  position: absolute;
  left: 0;
  right: 0;
  top: 14%;
  margin-left: auto;
  margin-right: auto;
  max-width: 640px;
  height: 660px;
  background-color: rgb(255, 255, 255);
  padding: 40px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  z-index: 11;
}


.close-icon {
  font-size: 14px;
  color: rgb(109, 109, 109);
  float: right;
  cursor: pointer;
}

.form_inputy {
  display: flex;
  flex-direction: column;
}

.auth-modal input[type=email],
.auth-modal input[type=text], 
.auth-modal input[type=tel],
#sprava
{
  all:unset;
  padding: 8px;
  margin: 8px;
  font-size: 17px;
  border: 1px solid rgba(149, 157, 165, 0.4);
  font-family: "CamptonLight";
}

.auth-modal label, #GDPR {
  margin-left: 8px;
  color: rgb(109, 109, 109);
  font-family: "CamptonMedium";
}

.zoslabenie {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.33;
  background: #EDF0F3;
  z-index: 10;
}
.zoslabenie-obrazok {
  opacity: 0;
}

#nadpis_form {
  color: $tmavo-modra;
  font-family: "CamptonBold";
  font-size: 26px;
  margin-left: 8px;
}

#nadpis_form-login {
  color: $tmavo-modra;
  font-family: "CamptonBold";
  font-size: 26px;
}

#popis_form {
  margin-left: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: rgb(109, 109, 109);
  font-family: "CamptonLight";
  font-size: 15px;
}

.popis_form {
  margin-left: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: rgb(109, 109, 109);
  font-family: "CamptonLight";
  font-size: 15px;
}


// MENU LOGIN FORM


.auth-modal-login {
  position: absolute;
  left: 0;
  right: 0;
  top: 10%;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  height: 500px;
  background-color: rgb(255, 255, 255);
  padding: 40px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  z-index: 11;
}


// SLOVNIK

.slovnikyObidva {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
  margin-top: 20px;
}

.table-data-slovnik {
  all: unset;
  flex: 1;
	text-align:center;
  border-bottom: 2px solid white;
}

.table-data-slovnik:hover {
  border-bottom: 2px solid $hover;
}



.table-row-slovnik {
	display:flex;
  align-items: center;
  border: 2px solid #005E9D;
  // height: 29px;
  padding: 7px 0;
  font-family: "CamptonMedium";
  justify-content: flex-start;
	margin-bottom: 10px;
  cursor: pointer;
  width: 99.6%;
}

#prelozene_slova {
  text-align: center;
  margin: 10px 0;
}

.nazvy_slovnik{
  margin-bottom: 20px;
  margin-top: 20px;
}

// KONEC SLOVNIK
 

.nacrty_schemy {
  display: flex;
  flex-direction: column;
}

.nacrt {
  font-family: "CamptonBook";
  font-size: 13px;
}

.sedive {
  color: gray;
  border: gray 2px solid;
}


.element_nazov_input {
  all: unset;
  flex: 1;
  text-align: center;
  color:white;
  text-transform:uppercase;
  padding: 0 1px
}

.final_info {
  display: flex;
  justify-content: center;
  align-items: end;
  // margin-top: 40px;
  margin: 40px 60px;
}

.tlacitka-formular {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 30px;
}

#sprava {
  height: 80px;
}


// EDITOVANIE





.table-data-edit {
  all: unset;
	text-align:center;
  width: 98%;;
  flex: 1;
  border-bottom: 2px solid white;
  cursor: pointer;
}


.siedma_data-edit {
  all: unset;
	text-align:center;
  width: 98%;;
  flex: 1;
  border-bottom: 2px solid white;
  cursor: pointer;
}

.vybavenie-edit{
  all: unset;
	text-align:start;
  flex: 1;
  border-bottom: 1px solid $tmavo-modra;
  margin-bottom: 2px;
  cursor: pointer;
}



.table-data-edit-tlacitko{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 0;
  flex: 1;

}

.table-data-edit-tlacitko{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 0;
}

.table-data-edit-tlacitko:last-child {
	flex: 0 0 27px,
}

.tlacitko-vymazat-male {
  margin-top: 2px;
  border: 1px solid $zakaz;

  background-color: white;
  font-family: "CamptonMedium";
  color: $zakaz;
}

.tlacitko-vymazat-male:hover {
  background-color: $zakaz;
  border: 1px solid $zakaz;
  color: white;
  cursor: pointer;
}




.stlpce{
  flex: 1;
}


.stlpce:last-child {
	flex: 0 0 27px;
  margin-right: 2px;
}

.tlacitko-pridat-stlpec {
  border: 1px solid $tmavo-modra;
  margin-left: 6px;
  padding: 4px 10px;
  background-color: white;
  color: $tmavo-modra;
  font-family: "CamptonMedium";
  font-size: 16px;
}

.tlacitko-pridat-stlpec:hover{
  background-color: $tmavo-modra;
  border: 1px solid $tmavo-modra;
  color: white;
  cursor: pointer;

}



.table-data-edit:hover {
  border-bottom: 2px solid $hover;
}

.table-data-edit:last-child {
	flex: 0 0 27px,
}


.table-row-edit {
	display:flex;
  align-items: center;
  border: 2px solid #005E9D;
  // height: 29px;
  padding: 7px 0;
  font-family: "CamptonMedium";
  justify-content: flex-start;
  width: 99.6%;
}

.table-row-edit-column{
  display:flex;
  align-items: center;
  border: 2px solid $zakaz;
  // height: 29px;
  padding: 7px 0;
  font-family: "CamptonMedium";
  justify-content: flex-start;
  width: 99.6%;
}

.tlacitko-vymazat {
  border: 1px solid $zakaz;
  margin-left: 6px;
  padding: 4px 10px;
  background-color: white;
  color: $zakaz;
  font-family: "CamptonMedium";
  font-size: 16px;
}


.tlacitko-vymazat:hover {
  background-color: $zakaz;
  border: 1px solid $zakaz;
  color: white;
  cursor: pointer;
}

.element_nazov_edit {
  all: unset;
  width: 20px;
  flex: 1;
  text-align: center;
  color:white;
  // text-transform:uppercase;
  padding: 0 1px;
}

.siedma_item-edit {
  all: unset;
  width: 20px;
  flex: 1;
  text-align: center;
  color:white;
  text-transform:uppercase;
  padding: 0 1px;
}


.tooltipExtra {
  font-size: 9px;
}

.tooltipExtraNazvy {
  font-size: 9px;
  padding-right: 4px;

}






/* CSS */
.button-24 {
  background: #FF4742;
  border: 1px solid #FF4742;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.button-24:hover,
.button-24:active {
  background-color: initial;
  background-position: 0 0;
  color: #FF4742;
}

.button-24:active {
  opacity: .5;
}


.trashcan {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}


.trashcan:hover {
cursor: pointer;
}

.containerCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 288px;
  height: 325px;
  border-radius: 24px;
  border: 1px solid #D4D4D4;
}

.cardIcons {
  width: 28px;
  padding: 6px;
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(317deg) brightness(105%) contrast(104%);
  cursor: pointer;
}

.dalsiContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: start;
  border-radius: 16px;
  background: #E0F3FF;
  margin: 8px 8px 0 8px;
}

.objednavky {
  border-radius: 100px;
  border: 1px solid #7D7D7D;
  background: #FFF;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
  padding: 4px 8px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.nazovMenuServo{
  font-size: 18px;
  font-family: "CamptonMedium";
}

.velkyNazov {
  margin-left: 30px;
  font-family: "CamptonBold";
  font-size: 28px;
}

.kategoria {
 
padding: 12px 14px;
margin-left: 10px;
border-radius: 100px;
background: #FFF;
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
margin-bottom: 10px;
}

.black_filter {
  filter: saturate(100%)  brightness(0) ;
}


.siedma_tabulka .cele_nazvy  > .row:first-child{
  /* Your styling properties here */
  flex: 0 0 80px;
  /* Add any other styles you want */
}

.siedma_tabulka .cele_nazvy  > .row:nth-last-child(2){
  /* Your styling properties here */

  flex: 0 0 33px;
  /* Add any other styles you want */
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 45px;
  aspect-ratio: 1;
  --c: no-repeat linear-gradient(#000 0 0);
  background: 
    var(--c) 0%   50%,
    var(--c) 50%  50%,
    var(--c) 100% 50%;
  background-size: 20% 100%;
  animation: l1 1s infinite linear;
}
@keyframes l1 {
  0%  {background-size: 20% 100%,20% 100%,20% 100%}
  33% {background-size: 20% 10% ,20% 100%,20% 100%}
  50% {background-size: 20% 100%,20% 10% ,20% 100%}
  66% {background-size: 20% 100%,20% 100%,20% 10% }
  100%{background-size: 20% 100%,20% 100%,20% 100%}
}




// *, *:after, *:before {
// 	box-sizing: border-box;
// }

// :root {
// 	--c-action-primary: #2e44ff;
// 	--c-action-primary-accent: #e9e5ff;
// 	--c-action-secondary: #e5e5e5;
// 	--c-text-primary: #0d0f21; 
// 	--c-text-secondary: #6a6b76;
// 	--c-background-primary: #d0d1de;
// }

// body {
// 	font-family: "Inter", sans-serif;
// 	color: var(--c-text-primary);
// 	background-color: var(--c-background-primary);	
// 	line-height: 1.5;
// }



.modal {
width: 300px;
	margin-left: auto;
	margin-right: auto;
	background-color: #FFF;
	border-radius: .5rem;
	box-shadow: 0 5px 15px rgba(#000, .2); 

	color: var(--c-text-primary);
	background-color: var(--c-background-primary);	
	line-height: 1.5;
  --c-action-primary: #005e9d;
	--c-action-primary-accent: #f2f2f2;
	--c-action-secondary: #e5e5e5;
	--c-text-primary: #0d0f21; 
	--c-text-secondary: #6a6b76;
	--c-background-primary: #ffffff;

}

.modal-header {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 1.5rem 1.5rem 1rem;
}

.logo-circle {
	width: 3.5rem;
	height: 3.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: var(--c-action-primary-accent);
	svg {
		max-width: 1.5rem;
	}
}

.btn-close {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2.25rem;
	height: 2.25rem;
	border-radius: .25rem;
	border: none;
	background-color: transparent;
	&:hover, &:focus {
		background-color: var(--c-action-primary-accent);
	}
  cursor: pointer;
}

.modal-body {
	padding: 1rem 1.5rem;
}

.modal-title {
	font-weight: 700;
}

.modal-description {
	color: var(--c-text-secondary);
}

.upload-area {
	margin-top: 1.25rem;
	border: none;
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23ccc' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
	background-color: transparent;
	padding: 3rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	&:hover, &:focus {
			background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%232e44ff' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
	}
  cursor: pointer;
} 

.upload-area-icon {
	display: block;
	width: 2.25rem;
	height: 2.25rem;
	svg {
		max-height: 100%;
		max-width: 100%;
	}
}

.upload-area-title {
	margin-top: 1rem;
	display: block;
	font-weight: 700;
		color: var(--c-text-primary);
}

.upload-area-description {
		display: block;
		color: var(--c-text-secondary);
	strong {
		color: var(--c-action-primary);
		font-weight: 700;
	}
}

.modal-footer {
	padding: 1rem 1.5rem 1.5rem;
	display: flex;
	justify-content: flex-end;
	[class*="btn-"] {
		margin-left: .75rem;
	}
}

.btn-secondary, .btn-primary {
	padding: .5rem 1rem;
	font-weight: 500;
	border: 2px solid var(--c-action-secondary);
	border-radius: .25rem;
	background-color: transparent;
  cursor: pointer;
}

.btn-primary {
	color: #FFF;
	background-color: var(--c-action-primary);
	border-color: var(--c-action-primary);
}

// Landing page

.hlavnyLanding {
  height: 100vh;
  width: 100vw;
  background-color: #FBF9F5;
}





/* CSS */
.button-18 {
  align-items: center;
  background-color: $tmavo-modra;
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: "CamptonBook";
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  max-width: 480px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
}

.button-18:hover,
.button-18:focus { 
  background-color: #16437E;
  color: #ffffff;
}

.button-18:active {
  background: #09223b;
  color: rgb(255, 255, 255, .7);
}

.button-18:disabled { 
  cursor: not-allowed;
  background: rgba(0, 0, 0, .08);
  color: rgba(0, 0, 0, .3);
}

.buttony {

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  align-items: center;
}

.navLanding{
  width: 100vw;
  margin-bottom: 40px;
}

.button-19 {
  align-items: center;
  background-color: white;
  border: 1px solid $tmavo-modra;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  display: inline-flex;
  font-family: "CamptonBook";
  font-size: 12px;
  font-weight: 400;
  color: $tmavo-modra;
  justify-content: center;
  line-height: 20px;
  max-width: 480px;
  min-height: 26px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
}

.landingHeadline{
  font-family: "CamptonBold";
  font-size: 52px;
  width: 507px;
}

.obrazkyNahravanie {
  max-width: 100px;
  max-height: 200px;
  width: auto;
  height: auto;
  object-fit: contain;
}

.jedenObrazokNahravanie{
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 14px;
  height: 220px;
  width: 110px;

}

.ineObrazky {
  width: 300px;
  height: 100px;
}